.ems-hierarchy-banner {
  display: flex;
  width: 100%; 
}

.ems-hierarchy-banner-image {
  display: flex;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.ems-hierarchy-banner-carousel {
  width: 100vw;
}