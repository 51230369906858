.ems-footer {
  display: flex;
  min-height: 100px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 0 50px;
  align-items: center;
  margin-top: 20px;
}

.ems-footer-nav {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  margin-left: auto;  
}

.ems-footer-nav-item {
  display: flex;
  text-decoration: none;
  padding: 5px 0;
  margin: 0 10px;
}

.ems-footer-nav-item:hover {
  text-decoration: underline;
}