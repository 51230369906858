.ems-asset-list-item-details-inner {
  text-align:left;
}
.ems-asset-list-item-title {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  font-weight:400;
}
.ems-asset-list-item-metadata-list {
  display:flex;
  flex-direction:column;
}
