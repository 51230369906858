.ems-sign-in {
  display: flex;
  justify-content: center;
}
.ems-header--vertical .ems-sign-in {
  margin: 64px auto;
}
.ems-sign-in .ems-main-inner {
  justify-content: center;
  display:flex;
  min-height: 300px;
}

.ems-sign-in-form {
  max-width: 400px;
  min-width: 200px;
  align-self: center;
}

.ems-sign-in-form-input,
.ems-sign-in-form-button {
  width: 100%;
}

.ems-reset-password-link {
  display: inline-block;
}

.ems-okta-login-link, .ems-cognito-login-link {
  display: block;
  text-align: right;
}

.ems-sign-in-form-logged-out-warning {
  margin-bottom: 1rem;
}