.ems-asset-list-heading-count:before {
  content:" - ";
}

.ems-asset-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}



.ems-sorting-wrapper {
  display:flex;
  flex-direction:row;
  justify-content: flex-end;
}
.ems-sorting-selector, .ems-sorting-selector:focus {
  background-color:transparent;
  border:none;
  outline:none;
}