.ems-item-view-hierarchy-content {
  display:flex;
  flex-direction:column;
}
.ems-item-view-hierarchy-content-play-original .ems-item-view-content {
  flex-direction:column;
}
.ems-item-view-hierarchy-content-play-original .ems-item-view-content-image {
  width: 100%;
}
.ems-item-view-play-original-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0, 0.4);
}

.ems-item-view-hierarchy-content-play-original .ems-item-view-content-details {
  width: 100%;
  margin-top: 30px;
}

.ems-item-view-hierarchy-content-play-original .ems-item-view-content-details-buttons {
  margin-bottom: 5px;
}

.ems-item-view-content {
  display: flex;
  flex-direction:row;
}
.ems-item-view-content-image {
  width: 50%;
  background-color: rgba(0,0,0,0.1);
}
.ems-item-view-content-image-clickable {
  cursor: pointer;
}
.ems-item-view-content-image img {
  max-width: 100%;
}
.ems-item-view-content-details {
  max-width: 50%;
  display:flex;
  flex-direction:column;
}

.ems-item-view-content-details-buttons {
  display: flex;
  flex-direction:row;
}

.ems-item-view-content-details-metadata-item-title {
  margin-right: 5px;
}
.ems-item-view-content-details-metadata-item-title::after {
  display: inline-block;
  content: ':';
}
.ems-collection-basket-button {
  align-self: center;
  margin-left: auto;
}