/* Custom Classes */
.ems-container {
  flex: 1;
  min-height: 100%;
  display: flex;
  align-items: stretch;
}

.ems-wrapper {
  display: flex;
  flex: 1;
  min-height: 100%;
}

.ems-main {
  flex: 1;
  margin:0px auto;
}

.ems-warning {
  background-color: #fafad2;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e5e5ad;
  color: #212121;
}

.ems-general-loading {
  display: flex;
  align-self: center;
  margin: 0 auto;
  fill: #3e3e3e;
}

.ems-app {
  flex: 1;
  min-height: 100%;
  display: flex;
  overflow:hidden;
}
.ems-app--vertical .ems-container {
  flex-direction: column;
}
.ems-app--horizontal .ems-container {
  flex-direction: row;
}
/* temporary fix for layout changes */
.ems-app--horizontal .ems-footer {
  display: none;
}

.ems-text-center {
  text-align: center;
}
/* ----------------------- heading base */
.ems-heading-wrapper {
  display:flex;
  flex-direction:row;
  flex:1;
}
  .ems-heading {
    flex:1;
  }


/* ----------------------- items base */
.ems-item {
  display:flex;
}
  .ems-item-inner {
    display: flex;
    flex-direction: column;
    width:100%;
  }

/* ----------------------- thumbnail base */
.ems-thumbnail {
  width:100%;
  overflow:hidden;
}
  .ems-thumbnail-inner {
    width:100%;
    height:100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

/* ----------------------- metadata */
.ems-metadata-list {
  display:flex;
  flex-direction: column;
}
  .ems-metadata-list-item {
    display: flex;
    flex-direction: row;
    align-items:center;
  }

.ems-loading-indicator {
  margin: 0 auto;
  display: flex;
  align-items: center;
}