.ems-folder-list-heading-wrapper {
  display: flex;
  align-items:center;
}

.ems-folder-list-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  max-height: auto;
}

.ems-folder-list-wrapper-hidden {
  max-height: 0;
}