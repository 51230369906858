.ems-breadcrumb-wrapper {
  display: flex;
  list-style: none;
  padding: 0;
}
.ems-breadcrumb-item {
  display:flex;
  align-items:center;
}
.ems-breadcrumb-item:last-of-type a {
  color: inherit;
  opacity: 0.8;
}
.ems-breadcrumb-item:not(:last-of-type)::after {
  content: ">";
  display:flex;
  font-size:0.8rem;
  opacity:0.5;
  margin-top:-4px;
}