.ems-hierarchies-list-heading {
  flex:unset;
}
.ems-hierarchies-list-toggle {
  display: flex;
  align-items: center;
}
.ems-hierarchies-list-wrapper {
  overflow: hidden;
}
.ems-hierarchies-list-wrapper-hidden {
  max-height: 0;
}