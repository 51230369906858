.ems-search-bar {
  display: flex;
  flex: 1;
  margin-top: 30px;
}

.ems-search-bar-form {
  display: flex;
  flex: 1;
}

.ems-search-bar-input {
  flex: 1;
}