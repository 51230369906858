.ems-zoom-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  max-width: 100vw;
}

.ems-zoom-header {
  height: 50px;
  align-items: center;
  display: flex;
  padding: 0 10px;
  color: #fff;
}

.ems-zoom-content {
  flex: 1;
}

.ems-zoom-image-container {
  max-height: calc(100vh - 100px);
  overflow: scroll;
  position: relative;
}

.ems-zoom-image-container-img-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 10px 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.ems-zoom-image-container-img-full-size {
  max-height: none;
  max-width: none;
  display: block;
}
.ems-zoom-image-container-img-fit-to-width {
  max-width: 100%;
  display: block;
}
.ems-zoom-image-container-img-fit-to-screen {
  margin: 0 auto;
  max-width: 100%;
  max-height: calc(100vh - 110px);
  display: block;
}

.ems-zoom-footer {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 0 10px;
}

.ems-zoom-footer > div {
  flex: 1;
  text-align: center;
}

.ems-zoom-footer > div:first-of-type {
  text-align: left;
}

.ems-zoom-footer > div:last-of-type {
  text-align: right;
}

.ems-zoom-button {
  background: transparent;
  border: none;
  color: #9e9e9e;
  font-size: 0.8em;
  outline: none;
  cursor: pointer;
}

.ems-zoom-button-active {
  color: #fff;
}

.ems-zoom-pdf-container {
  justify-content: center;
  display: flex;
}
