/* Reset CSS */
html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding:0px;
  margin:0px;
  min-height: 100%;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
*:before,
*:after {
  box-sizing: border-box;
}

html body {
  width:auto;
  margin:0px;
  padding:0px;
  min-height: 100vh;
  display: flex;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-overflow-scrolling: touch;
}
html body :focus {
  outline: none;
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
  display: block;
}
audio,canvas,progress,video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],template {
  display: none;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
small {
  font-size: 80%;
}
sub,sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  box-sizing: content-box;
  height: 0;
  opacity:0.25;
}
pre {
  overflow: auto;
}
code,kbd,pre,samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,input,optgroup,select,textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,html input[type="button"],input[type="reset"],input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 15px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
dl {
  margin-top: 0;
  margin-bottom: 30px;
}
dt,
dd {
  line-height: 1.4;
}
dt {
  font-weight: 600;
}
dd {
  margin-left: 0;
}



/* Set base style and colours */
mark {
  background: #ff0;
}
hr {
  color:#DDDDDD;
}
fieldset {
  border: 1px solid #c0c0c0;
}

a:active,a:hover {
  outline: 0;
}


