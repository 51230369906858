.ems-play-all-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: row;
  max-height: 100vh;
  max-width: 100vw;
  color: #fff;
}

.ems-play-all-container .ems-play-all-control-button {
  background-color: #009ee3;
  color: #fff;
}

.ems-play-all-container .ems-button-icon {
  background-color: transparent;
  color: #fff;
}

.ems-button-play-type {
  display: flex;
  flex-direction: row;
  align-items:center;
}

.ems-play-all-control-button.ems-button-play-type-original, .ems-play-all-control-button.ems-button-play-type-preview:hover {
  background-color: #fff;
  color: #009ee3;
}

.ems-play-all-control-button.ems-button-play-type-original:hover, .ems-play-all-control-button.ems-button-play-type-preview {
  background-color: #009ee3;
  color: #fff;
}

.ems-button-play-type-text {
  display: flex;
  margin-left: 5px;
}

.ems-play-all-header {
  height: 50px;
  align-items: center;
  display: flex;
  padding: 0 10px;
}

.ems-play-all-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ems-play-all-controls {
  justify-content: center;
  align-items: center;
  display: flex;
}

.ems-play-all-player {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.ems-play-all-sidebar {
  display: flex;
  max-width: 25%;
  height: 100vh;
  flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.ems-play-all-sidebar-playlist-header {
  width: 100%;
  flex: unset;
}

.ems-play-all-sidebar-playlist-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.ems-play-all-sidebar-playlist {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ems-play-all-sidebar-playlist-item {
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  flex-direction: row;
  cursor: pointer;
}

.ems-play-all-sidebar-playlist-item-type {
  width: 30px;
}

.ems-play-all-sidebar-playlist-item-text {
  width: calc( 100% - 30px);
}

.ems-play-all-close-button {
  cursor: pointer;
}

.ems-play-all-close-mobile {
  display: none;
}

.ems-play-all-sidebar-toggle {
  display: none;
}

@media (max-width: 1100px) { 
  .ems-play-all-content {
    padding: 0 !important;
  }

  .ems-play-all-sidebar {
    max-width: auto;
  }

  .ems-play-all-sidebar.ems-play-all-sidebar-mobile-show {
    left: 0;
    max-width: 100vw;
  }

  .ems-play-all-sidebar.ems-play-all-sidebar-mobile-hidden {
    left: 100vw;
    max-width: 100vw;
  }

  .ems-play-all-control-button {
    margin: 0;
    margin-right:3px;
  }


  .ems-play-all-control-button:last-of-type {
    margin: 0;
  }

  .ems-button.ems-play-all-sidebar-toggle {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: #009ee3;
    color: #fff;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
  }

  .ems-play-all-sidebar-toggle-text {
    margin-left: 5px;
    display: flex;
  }

  .ems-play-all-sidebar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    background-color: rgba(0,0,0, 0.8);
  }

  .ems-button-close {
    display: none;
  }

  .ems-button.ems-play-all-close-mobile {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    height: 25px;
    background-color: transparent;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ems-play-all-container {
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
  }
}
