.ems-hierarchies-list-item-details-inner {
  text-align:center;
}
.ems-hierarchies-list-item-title {
  width:auto;
}
.ems-hierarchies-list-item-thumbnail-inner {
  opacity:1;
  transform: scale(1);
  transition: transform 0.5s ease-out;
}
  .ems-hierarchies-list-item:hover .ems-thumbnail-inner {
    transform: scale(1.1);
    transition: transform 0.125s linear;
  }