.ems-reset-password {
  display: flex;
  justify-content: center;
}
.ems-header--vertical .ems-reset-password {
  margin: 64px auto;
}
.ems-reset-password .ems-main-inner {
  justify-content: center;
  display:flex;
}

.ems-reset-password-form {
  max-width: 450px;
  min-width: 200px;
  align-self: center;
}

.ems-reset-password-form-input,
.ems-reset-password-form-button {
  width: 100%;
}